import React, { useEffect, useState } from 'react';
import { formatToCurrencyWithScale, roundAmountByCurrencyPair } from '../../../helpers/FormatHelper';
import { confirmDealBuyBack, getDealBuyBackAllInfo } from '../../../networking/NetworkingDeals';
import { getStyle } from '../../../styles/styles';
import LoadingBox from '../../shared/LoadingBox';
import AlertBox from '../../shared/AlertBox';

const style = getStyle();

const DealInfo = (props) => {

    console.log(props)
    let { dealHeaderInfo, isOriginalDeal } = props

    let renderForm = []
    if (dealHeaderInfo.DealHeaderID > 0) {
        renderForm.push(<>
            <h3 className="uk-heading-divider" style={style.titleStyle}>
                {isOriginalDeal ? 'Original Deal' : 'Buyback Deal'} - Deal ID: {dealHeaderInfo.DealHeaderID}
            </h3>
            <div div className="uk-grid">

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">We Buy</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(dealHeaderInfo.BuyAmount, dealHeaderInfo.BuyCurrency)}  {dealHeaderInfo.BuyCurrency}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">We Sell</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(dealHeaderInfo.SellAmount, dealHeaderInfo.SellCurrency)}  {dealHeaderInfo.SellCurrency}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Customer Rate</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{roundAmountByCurrencyPair(dealHeaderInfo.CustomerRate, dealHeaderInfo.BuyCurrency, dealHeaderInfo.SellCurrency)}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Type</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{dealHeaderInfo.ForwardType != null ? `${dealHeaderInfo.ForwardType} ` : ''}
                            {`${dealHeaderInfo.dealTypeName} `}
                        </h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Value Date</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{dealHeaderInfo.ValueDate}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Origin</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{dealHeaderInfo.DealOrigin}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Profit</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(dealHeaderInfo.ProfitAmount, dealHeaderInfo.ProfitCurrency)}  {dealHeaderInfo.ProfitCurrency}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Deal Paid Status</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{dealHeaderInfo.DealPaidStatus}</h4>
                    </div>
                </div>

                <div className="uk-width-1-2 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Created By</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{dealHeaderInfo['Created By']} on {dealHeaderInfo['Created On']}</h4>
                    </div>
                </div>
            </div>
        </>)
    }

    return renderForm
}

const DealBuybackInformationForm = (props) => {

    console.log(props)

    let { dealHeaderInfo } = props

    const [originalDeal, setOriginalDeal] = useState({})
    const [buybackDeal, setBuyBackDeal] = useState({})
    const [netAmount, setNetAmount] = useState(0)
    const [netAmountCurrency, setNetAmountCurrency] = useState('')
    const [netFeeAmount, setNetFeeAmount] = useState(0)
    const [netFeeAmountCurrency, setNetFeeAmountCurrency] = useState('')
    const [originalFeeAmount, setOriginalFeeAmount] = useState(0)
    const [originalFeeAmountCurrency, setOriginalFeeAmountCurrency] = useState('')
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState({});
    const [enableConfirmation, setEnableConfirmation] = useState(false)

    useEffect(() => {
        const loadDealBuyBackAllInfo = async () => {
            let dealBuyBackAllInfo = await getDealBuyBackAllInfo(dealHeaderInfo.DealHeaderID)
            console.log(dealBuyBackAllInfo)
            if (dealBuyBackAllInfo !== undefined && dealBuyBackAllInfo.data !== undefined && Array.isArray(dealBuyBackAllInfo.data)) {
                setOriginalDeal(dealBuyBackAllInfo.data[0].OriginalDeal)
                setBuyBackDeal(dealBuyBackAllInfo.data[0].BuybackDeal)

                setNetAmount(dealBuyBackAllInfo.data[0].NetAmount)
                setNetAmountCurrency(dealBuyBackAllInfo.data[0].NetAmountCurrency)

                setNetFeeAmount(dealBuyBackAllInfo.data[0].NetFeeAmount)
                setNetFeeAmountCurrency(dealBuyBackAllInfo.data[0].NetFeeAmountCurrency)

                setOriginalFeeAmount(dealBuyBackAllInfo.data[0].OriginalDealFeeAmount)
                setOriginalFeeAmountCurrency(dealBuyBackAllInfo.data[0].OriginalDealFeeCurrency)
                setEnableConfirmation(dealBuyBackAllInfo.data[0].BuybackDeal !== undefined && dealBuyBackAllInfo.data[0].BuybackDeal.DealPaidStatus === 'Unpaid')
            }
            else {
                setOriginalDeal({})
                setBuyBackDeal({})

                setNetAmount(0)
                setNetAmountCurrency('')

                setNetFeeAmount(0)
                setNetFeeAmountCurrency('')

                setOriginalFeeAmount(0)
                setOriginalFeeAmountCurrency('')
                setEnableConfirmation(false)
            }
        }

        loadDealBuyBackAllInfo()

    }, [dealHeaderInfo.DealHeaderID]);

    const onConfirmBuyBack = () => {
        setLoading(true)

        confirmDealBuyBack(originalDeal.DealHeaderID, buybackDeal.DealHeaderID, netAmount, netAmountCurrency, netFeeAmount, netFeeAmountCurrency,
            localStorage.getItem('UserID'), '').then(
            (json) => {
                console.log(json)
                let alertMessage = '', alertTitle = ''
                if (json === undefined) {
                    alertMessage = 'Something went wrong. Please try again or contact the administrator.';
                    alertTitle = 'Error'
                    setAlertMessage({
                        ...alertMessage,
                        title: alertTitle, showMessage: true, message: alertMessage
                    })
                }
                else {
                    switch (json.httpStatusCode) {
                        case 200:
                            {
                                if (json.success) {
                                    alertMessage = 'Net Settlement Corfirmation completed successfully!';
                                    alertTitle = 'Success'
                                }
                                else {
                                    alertMessage = 'Something went wrong. Please try again or contact the administrator.';
                                    alertTitle = 'Error'
                                }
                                setAlertMessage({
                                    ...alertMessage,
                                    title: alertTitle, showMessage: true, message: alertMessage
                                })
                                break;
                            }
                        default:
                            alertMessage = 'Something went wrong. Please try again or contact the administrator.';
                            alertTitle = 'Error';

                            if (json.ErrorMessage !== undefined) {
                                alertMessage = json.ErrorMessage
                            } else if (json.httpErrorMessage !== undefined) {
                                alertMessage = json.httpErrorMessage;
                            }
                            setAlertMessage({
                                ...alertMessage,
                                title: alertTitle, showMessage: true, message: alertMessage
                            })
                            break;
                    }
                }
            }
        ).finally(() => { setLoading(false) });
    }

    const closeAlertMessage = () => {
        setAlertMessage({ ...alertMessage, showMessage: false, title: '', message: '' })
    }

    return <>
        <div>
            <div className="uk-width-1-1 uk-grid">
                <div className="uk-width-1-1"><label className="uk-form-label">Customer Name</label></div>
                <div className="uk-width-1-1" style={{ textAlign: 'left' }}><h4 className="h4-deal">{dealHeaderInfo.Name} ({dealHeaderInfo['Customer ID']})</h4></div>
            </div>
            <DealInfo key={'df-1'} dealHeaderInfo={originalDeal} isOriginalDeal={true} />
            <DealInfo key={'df-2'} dealHeaderInfo={buybackDeal} isOriginalDeal={false} />
            <h3 className="uk-heading-divider" style={style.titleStyle}>
                Net Settlement Amounts
            </h3>
            <div className="uk-width-1-1 uk-grid">
                <div className="uk-width-1-3 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Net Amount</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(netAmount, netAmountCurrency)}  {netAmountCurrency}</h4>
                    </div>
                </div>
                <div className="uk-width-1-3 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Fee Net Amount</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(netFeeAmount, netFeeAmountCurrency)}  {netFeeAmountCurrency}</h4>
                    </div>
                </div>
                <div className="uk-width-1-3 uk-grid mg-t-5">
                    <div className="uk-width-1-1"><label className="uk-form-label">Customer Deal Fee Amount</label></div>
                    <div className="uk-width-1-1" style={{ textAlign: 'left' }}>
                        <h4 className="h4-deal">{formatToCurrencyWithScale(originalFeeAmount, originalFeeAmountCurrency)}  {originalFeeAmountCurrency}</h4>
                    </div>
                </div>
            </div>
            <hr />
            <div><button className="uk-button uk-button-green" type="button" onClick={onConfirmBuyBack} disabled={!enableConfirmation}>
                Confirm Net Settlement</button></div>
            <AlertBox id="buyBackAlert"
                open={alertMessage.showMessage}
                title={alertMessage.title}
                message={alertMessage.message}
                type="Ok"
                onClose={closeAlertMessage}
                okClick={closeAlertMessage} />
            <LoadingBox loading={loading} />
        </div>
    </>
}

export default DealBuybackInformationForm;
